.dept-form-container {
    width: 100%;
    margin: 0 auto;
    /* margin-top: 7rem; */
    padding: 20px;
    /* background-color: #f9f9f9; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
  .dept-form-header {
    display: flex;
    align-items: center;
    gap:1%;
    /* justify-content: space-between; */

    
 
    
   
  }
  
  .dept-form-header label {
    font-size: 16px;
    
    font-weight: bold;
    color: #333;
    
  }
  
  .dept-form-header select {
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dept-header-actions {
    display: flex;
    gap: 10px;
  }
  
  .dept-header-actions .dept-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .dept-header-actions .save-new {
    background-color: #28a745;
    color: white;
  }
  
  .dept-header-actions .save {
    background-color: #007bff;
    color: white;
  }
  
  .dept-header-actions .cancel {
    background-color: #dc3545;
    color: white;
  }
  
  .dept-header-actions .dept-button:hover {
    opacity: 0.9;
  }
  
  .dept-form-body {
    margin-top: 20px;
  }
  
  fieldset {
    border: none;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
  legend {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .dept-form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .dept-form-row label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .dept-form-row span {
    color: red;
  }
  
  .dept-form-row input, 
  .dept-form-row select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .dept-form-row input[type="file"] {
    padding: 5px;
  }
  
  #template-dropdown {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #template-dropdown:hover {
    border-color: #007bff;
  }
  
  .dept-form-header a button {
    background-color: #ffc107;
    border: none;
    margin: 0;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .dept-form-header a button:hover {
    opacity: 0.9;
  }
  .create-dept-breadcrumb {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .create-dept-chevron {
    /* margin: 0 1rem; */
    color: #666;
   
    
  }
  
  .create-dept-breadcrumb-link {
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
  }
  
  .create-dept-breadcrumb-link:hover {
    text-decoration: none;
  }
 .create-dept-breadcrumb-dept-opening{
  text-decoration: none;
  color:#666 ;
 }