
.setting-dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows cards to wrap to the next line */
    justify-content: flex-start; /* Aligns cards to the start of the row */
    width: 100%;
    height: auto; /* Allow height to adjust dynamically */
    gap: 15px; /* Adds spacing between cards */
    background-color: #fff; /* Ensure a clean background */
    border-radius: 8px; /* Add rounded corners */
  
}

.single-setting-card {
    flex: 1 1 calc(33.33% - 15px); /* Ensures three cards per row with spacing */
    max-width: calc(33.33% - 15px); /* Prevents cards from growing too large */
    box-sizing: border-box; /* Includes padding and border in width calculation */
}

/* Responsive Adjustments */
@media screen and (max-width: 992px) {
    .single-setting-card {
        flex: 1 1 calc(50% - 15px); /* Two cards per row on medium screens */
        max-width: calc(50% - 15px);
    }
}

@media screen and (max-width: 576px) {
    .single-setting-card {
        flex: 1 1 100%; /* One card per row on small screens */
        max-width: 100%;
    }
}