.profile-pic{
  height: 45vh;

  width: 22vw;
  border: 1px solid #007bff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}


.create-canidate-profile-pic-img {
  margin-left: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}


.candidate_alignment{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.line{
  width: 95vw;
  height: 0.8px;
  translate: 0 -10px 0;
}

.candidate_section_name{
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: space-between;
 
}
.create-candidate-addbtn{
  background-color: transparent;
  border: none;
  outline:none;
  color: #007bff;
}
.create-candidate-btnicon{
margin-top: auto;
}
.create-candidate-bottom-section{
  width: 100%;
  height: 10vh;
  background-color:#f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-candidate-bottom-section-save{
  background-color: #007bff;
  color: white;
  outline: none;
  margin-right: 1rem;
}
.create-candidate-bottom-section-cancel{
  background-color: transparent;
  color: black;
  border: 1px solid #007bff;
}
