.personal-settings-container {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
  
  .settings-tabs {
    display: flex;
    border-bottom: 1px solid #EDEDED;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .settings-tabs button {
    background-color: transparent;
    border: none;
    color: #00000080;
    border-radius: 0;
    cursor: pointer;
  }
  
  .settings-tabs .active {
    border-bottom: 2px solid #1E88E5;
    /* background: blue; */
    color: black;
  }
  
  .personal-details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
 
  
  /* Contact Details */
  .contact-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    width: 60%;
  }
  .contact-detail-header{
   font-weight: 500;
   /* font-size: 14px;
    background-color: red; */
    /* width: 100%; */
  }
  .personal-form-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .personal-input-group {
    /* font-family: ; */
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    width: 100%;
  }
  .personal-mobile-input {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .personal-mobile-input select {
    border: none;
    border-right: 1px solid #ccc;
    padding: 7px;
    font-size: 14px;
    width: auto;
    min-width: 80px;
    flex-shrink: 0; 
    height: 100%;
    outline: none;
    text-align: center;
  }
  
  .personal-mobile-input input {
    border: none;
    padding: 10px;
    flex-grow: 1; /* Allow the input to expand */
    font-size: 14px;
    outline: none;
  }

  
  .personal-input-group label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 5px;
    color: #7E89A5;
  }
  
  
  .personal-input-group input,
  .personal-input-group textarea {
    width: 100%;
    padding: 8px;
    font-family: 'poppins';
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .profile-section {
    width: 35%;
    display: flex;
    margin-top: 3%;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    text-align: center;
  }
  
  .image-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .profile-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #eef5ff; 
  }
  
  .upload-icon {
    position: absolute;
    bottom: 10px;
    right: 5px;
    background: white;
    border: 2px solid #007bff;
    color: #007bff;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* .upload-icon:hover {
    background: #007bff;
    color: white;
  } */
  
  /* input[type="file"] {
    display: none;
  } */
  
  .profile-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  
  .profile-role {
    font-size: 14px;
    color: gray;
    margin: 0;
  }
  
  
  /* Form Fields */
  .personal-form-group {
    display: flex;
    gap: 29px;
  }
  
  .personal-input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
  }
  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent clicks on the icon */
  }
  .personal-input-group .select1 {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #f9f9f9;
    cursor: pointer;
    appearance: none;
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }
  
  .personal-input-group .select1:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
  }
  
  .personal-input-group select option {
    padding: 10px;
    font-size: 14px;
    background: #fff;
  }
  /* .personal-input-group label {
    font-size: 14px;
    margin-bottom: 5px;
    background-color: red;
  } */
  
  .personal-input-group input,
  .personal-input-group textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-wrapper input {
    width: 100%;
    padding-right: 35px; /* Space for the eye icon */
  }
  
  .eye-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #555;
  }
  
  
  /* Location Details */
  .location-details {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Social Media Buttons */
  .social-links {
    display: flex;
    gap: 15px;
  }
  
  .social-btn {
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  
  .linkedin { background: #0077b5; color: white; }
  .x { background: black; color: white; }
  .google { background: red; color: white; }
  /* .change-password-section {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
  } */
  
  .setting-password-form {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  
  .setting-password-actions {
    display: flex;
    gap: 2%;
    margin-top: 20px;
  }
  
  .setting-cancel-btn {
    background: white;
    color: black;
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
  }
  
  .setting-save-btn {
    background: #1E88E5;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  .personal-setting-edit{
    margin-left: 1%;
    color: blue;
    font-size: 20px;
    background-color: transparent;
  }
  .location-setting-edit{
    margin-left: 1%;
    color: blue;
    font-size: 20px;
    background-color: white;
  }
  /* Input Field Styling */
.personal-input-feild,
.personal-input-mobileno,
.personal-input-officeno {
  width: 100%;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
}

.personal-input-feild:focus,
.personal-input-mobileno:focus,
.personal-input-officeno:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
}

/* Alignment for Input Fields */
.personal-form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.personal-input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

.personal-mobile-input {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.personal-mobile-input select {
  border: none;
  border-right: 1px solid #ccc;
  padding: 7px;
  font-size: 14px;
  width: auto;
  min-width: 80px;
  flex-shrink: 0;
  height: 100%;
  outline: none;
  text-align: center;
}

.personal-mobile-input input {
  border: none;
  padding: 10px;
  flex-grow: 1;
  font-size: 14px;
  outline: none;
}

/* Responsive Adjustments */
@media screen and (max-width: 576px) {
  .personal-form-group {
    flex-direction: column;
    gap: 10px;
  }

  .personal-mobile-input select {
    width: 20%;
  }

  .personal-mobile-input input {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .personal-form-group {
    flex-direction: column;
  }

  .personal-mobile-input select {
    width: 15%;
  }
}

@media screen and (max-width: 992px) {
  .personal-form-group {
    flex-wrap: wrap;
  }
}

/* Small devices (phones, 576px and below) */
@media screen and (max-width: 576px) {
  .contact-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .contact-form {
    width: 100%;
  }

  .profile-section {
    width: 100%;
    margin-top: 10px;
  }

  .personal-form-group {
    flex-direction: column;
    gap: 10px;
  }

  .personal-mobile-input select {
    width: 20%;
  }

  .personal-mobile-input input {
    width: 100%;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .upload-icon {
    width: 28px;
    height: 28px;
  }

  .social-links {
    flex-wrap: wrap;
    gap: 10px;
  }

  .setting-password-form {
    flex-direction: column;
  }
}

/* Medium devices (tablets, 768px and below) */
@media screen and (max-width: 768px) {
  .settings-tabs {
    flex-wrap: wrap;
    justify-content: center;
  }


  .contact-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .contact-form {
    width: 100%;
  }

  .profile-section {
    width: 100%;
    align-items: center;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .personal-form-group {
    flex-direction: column;
  }

  .personal-mobile-input select {
    width: 15%;
  }

  .social-links {
    flex-wrap: wrap;
  }
}

/* Large devices (laptops, 992px and below) */
@media screen and (max-width: 992px) {
  .contact-details {
    flex-direction: column;
    gap: 20px;
  }

  .contact-form {
    width: 80%;
  }

  .profile-section {
    width: 50%;
  }

  .profile-image {
    width: 130px;
    height: 130px;
  }

  .personal-form-group {
    flex-wrap: wrap;
  }

  .setting-password-form {
    flex-wrap: wrap;
  }
}

/* Extra large devices (desktops, 1200px and below) */
@media screen and (max-width: 1200px) {
  .profile-section {
    width: 40%;
  }

  .contact-form {
    width: 70%;
  }

  .profile-image {
    width: 140px;
    height: 140px;
  }
}
