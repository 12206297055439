@keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


.canidate-adddocument-popup{
    position: fixed;
    top:9vh;
    right: 0;
    width: 50vw;
    height: 86vh;
    background:var(--fullWhite);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 0 20px 0;
    border-radius: 8px;
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.6s ease-in-out;
    pointer-events: none;
    
   
    

}
.canidate-adddocument-popup.open{
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}
.canidate-adddocument-popup-header h3{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.canidate-adddocument-popup-header {
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: space-between;
    background-color:white;
    color:var(--fullBlack);
    border-radius: 8px 0px 0px 0px;
    border-bottom: 1px solid #CED4DA;

  }
  .canidate-adddocument-popup .canidate-adddocument-popup-close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color:var(--fullBlack);
}
.candidate-adddocument-popup-content{
    flex: 1; 
    overflow-y: auto; 
    padding: 15px;
    max-height: calc(100vh - 50px); 
    display: flex;
    flex-wrap: wrap;

   
}
.candidate-adddocument-popup-inputcontainer{
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-right: 3rem;
    padding-bottom: 1rem;
}
.candidate-adddocument-popup-label{
    color:#7E89A5 ;
    font-weight: 400;
    padding-bottom: 0.2rem;
    font-size: 14px;
}
.candidate-adddocument-popup-input{
    padding: 8px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;

}
.candidate-adddocument-popup-input:focus{
    border: 1px solid #ccc;
}
.ci-popup-line{
    height: 1px;
    width: 100%;
    background-color:#badfff;
    margin: 17.5rem 0 10px 0;
   
    
  }
  .candidate-adddocument-popup-bottomCon{
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}
  .adddocument-popup-cancelbtn{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: transparent;
    color: black;
    border: 1px solid #007bff;
    margin-right: 0.5rem;
  }
  .adddocument-popup-savebtn{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
  }
.candidate-adddocument-popup-fixedcontainer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.uploaded-file-name{
  background-color: #F5F7FA;
  font-size: 12px;
  padding: 0px 8px;
  border-radius: 5px;
}





.cd-search-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}
.cd-search-box{
  border-radius: 5px;
  width: 20vw;
  padding: 0px 10px;
  border: 1px solid #CED4DA;
}
.cd-search-box:focus{
  border: 1px solid #CED4DA;
}
.cd-search-addDocument-btn{
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  margin-right: 0.3rem;

}
.cd-buttons-container{
  display: flex;
  text-align: center;


}


.cd-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 1rem;
}

.cd-card {
  width: 280px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #fff;

}



.cd-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.cd-card-title {
  font-size: 14px;
  font-weight: 600;
}

.cd-card-status {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 500;
}

.cd-card-status.active {
  background-color: #c8f7dc;
  color: #0f5132;
}

.cd-card-status.inactive {
  background-color: #f8d7da;
  color: #842029;
}

.cd-card-body {
  padding:0 0.5rem;

}

.cd-card-body.content{
 
  font-size: 14px;
  font-weight: 500;
}

.candidate-document-expirydate-label {
  font-size: 12px;
  color: #afafaf;
  font-weight: 400;
}
.candidate-document-expirydate{
  font-size: 12px;
  font-weight: 400;
}

.expiry-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
}

.cd-card-actions {
  display: flex;
  gap: 4px;
  font-size: 16px;
  color: #3a3a3a;
  cursor: pointer;
}



.cd-card-actions svg:hover {
  color:#007bff;

}
